// @ts-ignore - socker-io-client does not have type definitions
import io from 'socket.io-client/dist/socket.io.js'
import config from '../config'

const options = { transports: ['websocket'] }

const useSocketService = false
const WS_BASE = useSocketService ? config.WS_BASE : config.API_BASE

const socket = {
  reports: io(`${WS_BASE}/reports`, options),
  appointments: io(`${WS_BASE}/appointments`, options),
  orders: io(`${WS_BASE}/orders`, options),
  requests: io(`${WS_BASE}/carrierRequests`, options)
}

export default socket
