import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GridCell } from '../../../styled/Grids'
import DatePicker from '../../DatePicker'
import moment from 'moment-timezone'
// TODO: Remove ts-ignores after configuring external resources in typescript
// @ts-ignore: Cannot find module.
import calendarRightArrowIcon from '../../../assets/images/right-date-arrow-icon.svg'
import DayTabs from './components/DayTabs'
import HourFormatSwitch from './components/HourFormatSwitch'
import ZoomSelector from './components/ZoomPicker'
import { getAppointmentCounts } from '../../../modules/appointments/selectors'
import { selectTableTimeSpan } from '../../../app/selectors'
import { Moment } from 'moment'
import { DayType } from './utils/types'
import { createDays } from './utils/functions'
import { getWorkingDayEnd } from '../../../utils/time'
import { getAppointmentsForDoors } from '../../../modules/TableAppointments/actions'
import { TableHeaderContainer } from './styles'

export interface TableHeaderProps {
  set24Format: (is24Format: boolean) => void
  setZoomLevel: (zoomLevel: number) => void
  is24Format: boolean
  zoomLevel?: number
}

const TableHeader = (props: TableHeaderProps) => {
  const dispatch = useDispatch()
  const { startDate, endDate, startTime, endTime, timezone } = useSelector(selectTableTimeSpan)
  const appointmentCounts = useSelector(getAppointmentCounts)
  const { set24Format, setZoomLevel, is24Format, zoomLevel } = props

  const days = useMemo(() => {
    return createDays(timezone, startDate).map((day: DayType) => {
      if (startDate && day.date.isSame(startDate, 'D')) {
        return {
          ...day,
          status: 'selected'
        }
      } else {
        return {
          ...day,
          status: day.id === 0 ? 'past' : 'active'
        }
      }
    })
  }, [timezone, startDate])

  const onDateChange = (date: Moment) => {
    const endDate = getWorkingDayEnd(date)

    dispatch(
      getAppointmentsForDoors({
        selectedStartDate: date,
        selectedEndDate: endDate,
        selectedEndShift: endDate
      })
    )
  }

  const handleDateChange = (date: Moment) => {
    onDateChange(date)
  }

  const handleDayTabChange = (id: number) => {
    const selectedDay = days[id]
    handleDateChange(selectedDay.date)
  }

  return (
    <TableHeaderContainer>
      <GridCell flex centered row noMaxHeight>
        <DatePicker
          HeaderGridDatePicker
          dateFormat='dddd, MMMM Do'
          onChangeStartDate={handleDateChange}
          onChangeEndDate={handleDateChange}
          startDate={startDate ? moment(startDate) : null}
          endDate={endDate ? moment(endDate) : null}
          onChangeStartTime={handleDateChange}
          onChangeEndTime={handleDateChange}
          startTime={moment(startTime)}
          endTime={moment(endTime)}
          rightArrowIcon={calendarRightArrowIcon}
          showIcon={true}
        />
      </GridCell>
      <GridCell flex centered noMaxHeight>
        <DayTabs
          days={days}
          handleClick={handleDayTabChange}
          appointmentCounts={appointmentCounts}
        />
      </GridCell>

      <GridCell flex centered noMaxHeight>
        <HourFormatSwitch is24Format={is24Format} onChange={set24Format} />
      </GridCell>
      <GridCell flex centered noMaxHeight>
        <ZoomSelector
          level={zoomLevel}
          minLevel={25}
          maxLevel={100}
          step={25}
          onChange={setZoomLevel}
        />
      </GridCell>
    </TableHeaderContainer>
  )
}

export default TableHeader
