import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_STATE, TableAppointmentsState } from './initialState'
import { getAppointmentsForDoors } from './actions'
import { deleteAppointment } from '../appointments/actions'

const updateAppointmentFn = (state: TableAppointmentsState, action: { payload: any }) => {
  const { appointment } = action.payload
  if (!state.appointments || !appointment?.id) {
    return state
  }

  const updatedAppointmentIndex = state.appointments.findIndex(appt => appt.id === appointment.id)
  if (updatedAppointmentIndex !== -1) {
    const updatedAppointment = state.appointments[updatedAppointmentIndex]
    state.appointments[updatedAppointmentIndex] = {
      ...updatedAppointment,
      ...appointment
    }
    // faster than Immer
    return {
      ...state,
      appointments: [...state.appointments]
    }
  }
}

const tableAppointments = createSlice({
  name: 'tableAppointments',
  initialState: INITIAL_STATE,
  reducers: {
    clearAppointments: state => {
      state.appointments = []
    },
    addAppointmentToTable: (state, action) => {
      state.appointments.push(action.payload)
    },
    setAppointments: (state, action) => {
      state.appointments = action.payload
    },
    editAppointment: updateAppointmentFn,
    getAppointmentsForDoorSuccess: (state, action) => {
      if (!state.appointments) {
        return state
      }
      state.appointments.push(...action.payload.appointments)
      state.getAppointmentsForDoorIsLoading = false
      state.getAppointmentsForDoorErrorMessage = null
    },
    updateAppointmentForDoorWithSocketAppointment: updateAppointmentFn,
    updateStateAppointment: updateAppointmentFn
  },
  extraReducers: builder => {
    builder
      .addCase(getAppointmentsForDoors.pending, state => {
        state.getAppointmentsForDoorsIsLoading = true
        state.getAppointmentsForDoorsErrorMessage = ''
      })
      .addCase(getAppointmentsForDoors.fulfilled, (state, { payload }) => {
        state.appointments = payload || []
        state.getAppointmentsForDoorsIsLoading = false
        state.getAppointmentsForDoorsErrorMessage = null
      })
      .addCase(getAppointmentsForDoors.rejected, (state, action) => {
        state.getAppointmentsForDoorsIsLoading = false
        state.getAppointmentsForDoorsErrorMessage = action.error.message || null
      })
      .addCase(deleteAppointment.fulfilled, (state, action) => {
        state.appointments = state.appointments.filter(
          appointment => appointment.id !== action.payload
        )
      })
  }
})

export const {
  addAppointmentToTable,
  clearAppointments,
  setAppointments,
  editAppointment,
  getAppointmentsForDoorSuccess,
  updateAppointmentForDoorWithSocketAppointment,
  updateStateAppointment
} = tableAppointments.actions

export default tableAppointments.reducer
