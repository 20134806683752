import React from 'react'

import { Button, ButtonPrimary } from '../../styled/Buttons'
import { DefaultParagraph, SectionTitle, Title } from '../../styled/Texts'
import {
  StyledModal,
  StyledModalAlertHeader,
  StyledModalContent,
  StyledModalFooter
} from '../../styled/Modals'
import CloseButton from '../CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { getDeletingAppointment } from '../../modules/appointments/selectors'
import {
  closeDeleteAppointment,
  closeUpsertAppointment
} from '../../modules/appointments/appointment-slice'
import { deleteAppointment } from '../../modules/appointments/actions'

const DeleteAppointmentModal = () => {
  const dispatch = useDispatch()
  const appointment = useSelector(getDeletingAppointment)

  const onClose = () => dispatch(closeDeleteAppointment())

  const onConfirmDelete = async () => {
    await dispatch(deleteAppointment(appointment.id))
    dispatch(closeDeleteAppointment())
    dispatch(closeUpsertAppointment())
  }

  if (appointment === null) {
    return null
  }

  return (
    <StyledModal isOpen contentLabel='Delete Appointment' size='small'>
      <StyledModalAlertHeader>
        <Title>Delete Appointment</Title>
        <CloseButton onClick={onClose} />
      </StyledModalAlertHeader>
      <StyledModalContent>
        <SectionTitle>Are you sure you want to delete this appointment?</SectionTitle>
        <DefaultParagraph>
          All orders will be removed from this appointment and will be available for scheduling.
        </DefaultParagraph>
      </StyledModalContent>
      <StyledModalFooter>
        <Button type='submit' onClick={onClose}>
          No
        </Button>
        <ButtonPrimary type='submit' onClick={onConfirmDelete}>
          Yes, Delete
        </ButtonPrimary>
      </StyledModalFooter>
    </StyledModal>
  )
}

export default DeleteAppointmentModal
