import Appointment from '../../types/Appointment'

export interface TableAppointmentsState {
  appointments: Appointment[]
  getAppointmentsForDoorIsLoading: boolean
  getAppointmentsForDoorErrorMessage: string | null
  getAppointmentsForDoorsIsLoading: boolean
  getAppointmentsForDoorsErrorMessage: string | null
}

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE: TableAppointmentsState = {
  appointments: [],
  getAppointmentsForDoorIsLoading: false,
  getAppointmentsForDoorErrorMessage: null,
  getAppointmentsForDoorsIsLoading: false,
  getAppointmentsForDoorsErrorMessage: null
}
