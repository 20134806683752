import { DayType } from './types'
import { getDayStart } from '../../../../utils/time'
import { Moment } from 'moment/moment'
import moment from 'moment'

export const createDays = (timezone: string, startDate?: Moment): DayType[] => {
  const today = (startDate || moment()).tz(timezone)
  return [
    {
      id: 0,
      status: 'past',
      date: getDayStart(today).subtract(1, 'days'),
      countKey: 'appointmentYesterdayCount'
    },
    {
      id: 1,
      status: 'active',
      date: getDayStart(today),
      countKey: 'appointmentTodayCount'
    },
    {
      id: 2,
      status: 'active',
      date: getDayStart(today).add(1, 'days'),
      countKey: 'appointmentNext1Count'
    },
    {
      id: 3,
      status: 'active',
      date: getDayStart(today).add(2, 'days'),
      countKey: 'appointmentNext2Count'
    },
    {
      id: 4,
      status: 'active',
      date: getDayStart(today).add(3, 'days'),
      countKey: 'appointmentNext3Count'
    }
  ]
}
