import React, { FC, useContext } from 'react'
import moment from 'moment-timezone'
import { appointmentStatusesMap } from '../../../utils/time'
import DropOnTableHoc, { DroppableProps } from '../components/DropOnTableHoc'
import { DoorStepTd } from './DoorSteps.styles'
import { useSelector } from 'react-redux'
import { createGetIsDoorAvailable } from '../../../door-schedule/door-schedule-slice'
import { DoorEntity } from '../../../doors/doors-slice'
import TimeTableContext from '../utils/TableContext'
import TableAppointmentCard from './components/TableAppointmentCard'
import Appointment from '../../../types/Appointment'

export const DEFAULT_APPOINTMENT_STATUS = appointmentStatusesMap.draft

export interface DoorStepProps extends DroppableProps {
  hour: moment.Moment
  first: boolean
  appointments: Appointment[]
  door: DoorEntity
  // eslint-disable-next-line react/no-unused-prop-types
  scrollable: React.RefObject<HTMLDivElement>
}

const DoorStep: FC<DoorStepProps> = ({
  drop,
  canDrop,
  hour,
  isOver,
  appointments,
  first,
  door
}) => {
  const getIsDoorAvailable = useSelector(createGetIsDoorAvailable)
  const { cellWidth, cellHeight } = useContext(TimeTableContext)

  const doorAvailable = getIsDoorAvailable({
    doorId: door.id,
    hour
  })

  const appointment = appointments[0]

  return (
    <DoorStepTd
      ref={doorAvailable ? drop : null}
      width={cellWidth}
      height={cellHeight}
      disabled={!doorAvailable}
      isOver={isOver}
      canDrop={canDrop && doorAvailable}
      title={doorAvailable ? '' : 'Door is not available at this time'}
    >
      &nbsp;
      {appointment && <TableAppointmentCard appointment={appointment} first={first} />}
    </DoorStepTd>
  )
}

export default DropOnTableHoc<DoorStepProps>(DoorStep)
