import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { RootState } from '../root-types'
import socket from '../utils/socket'
import {
  AppointmentWithSocketPayload,
  updateAppointmentWithSocketAppointment
} from '../modules/appointments/actions'
import { mergeAppointmentCounts } from '../modules/appointments/appointment-slice'

let appointmentSocketCallback: any = null
let reportSocketCallback: any = null
let socketInterval: number | null = null

let appointmentsMap: any = {}

const appointmentSocketCallbackGenerator =
  (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => (payload: any) => {
    if (payload.id) {
      appointmentsMap[payload.id] = payload
    } else {
      payload.forEach((a: any) => {
        appointmentsMap[a.id] = a
      })
    }
    console.warn({ appointmentsMap })
    dispatch(
      updateAppointmentWithSocketAppointment(
          Object.values(appointmentsMap) as unknown as AppointmentWithSocketPayload
      )
    )
    appointmentsMap = {}
  }
const reportSocketCallbackGenerator =
  (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => (payload: any) => {
    dispatch(mergeAppointmentCounts(payload))
  }

export const turnSocketConnectionsOff = (buildingId: number) => {
  clearInterval(socketInterval || 0)
  socket.appointments.off(`building:${buildingId}`, appointmentSocketCallback)
  socket.reports.off(`updateWarnings:${buildingId}`, reportSocketCallback)
}
export const isAppointmentTypeOutbound = (appointmentType: number) => appointmentType === 2
export const isAppointmentTypeInbound = (appointmentType: number) => appointmentType === 1
export const getAppointmentTypesOptions = () => [
  {
    label: 'ALL',
    value: 0
  },
  {
    label: 'INBOUND',
    value: 1
  },
  {
    label: 'OUTBOUND',
    value: 2
  }
]

// TODO: errors
// TODO: payload types
export const turnSocketConnectionsOn = (buildingId: number, dispatch: any) => {
  // @ts-ignore
  socketInterval = setInterval(() => {
    turnSocketConnectionsOff(buildingId)
    socket.appointments.on(`building:${buildingId}`, appointmentSocketCallback)

    socket.reports.on(`updateWarnings:${buildingId}`, reportSocketCallback)
  }, 60 * 1000)
  appointmentSocketCallback = appointmentSocketCallbackGenerator(dispatch)
  socket.appointments.on(`building:${buildingId}`, appointmentSocketCallback)

  reportSocketCallback = reportSocketCallbackGenerator(dispatch)
  socket.reports.on(`updateWarnings:${buildingId}`, reportSocketCallback)
}
