import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_STATE } from './initialState'
import {
  createAppointment,
  deleteAppointment,
  fetchAppointments,
  fetchAppointmentsNextPage,
  openEditAppointment,
  updateAppointment
} from './actions'

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState: INITIAL_STATE,
  reducers: {
    getAppointmentsFailure (state, { payload }) {
      state.appointments = []
      state.getAppointmentsIsLoading = false
      state.getAppointmentsErrorMessage = payload
    },
    getAppointmentLoading (state) {
      state.getAppointmentIsLoading = true
      state.getAppointmentErrorMessage = ''
    },
    getAppointmentFailure (state, { payload }) {
      state.appointment = null
      state.getAppointmentIsLoading = false
      state.getAppointmentErrorMessage = payload
    },
    createAppointmentLoading (state) {
      state.createAppointmentIsLoading = true
      state.createAppointmentErrorMessage = ''
    },
    createAppointmentFailure (state, { payload }) {
      state.deleteAppointmentIsLoading = false
      state.deleteAppointmentErrorMesage = payload
    },
    deleteAppointmentLoading (state) {
      state.deleteAppointmentIsLoading = true
      state.deleteAppointmentErrorMesage = ''
    },
    deleteAppointmentSuccess (state) {
      state.deleteAppointmentIsLoading = false
      state.deleteAppointmentErrorMesage = null
    },
    deleteAppointmentFailure (state, { payload }) {
      state.deleteAppointmentIsLoading = false
      state.deleteAppointmentErrorMesage = payload
    },
    updateAppointmentLoading (state, { payload }) {
      state.updateAppointmentIsLoading = payload
      state.updateAppointmentErrorMessage = null
    },
    setSearchAttributes (state, { payload }) {
      return {
        ...state,
        ...payload
      }
    },
    updateAppointmentSuccess (state, { payload }) {
      const appointment = payload
      const newAppointment = {
        ...appointment,
        meta: undefined
      }
      const emptyAppointmentIssues = {
        hasPastDate: false,
        hasLowInventory: false,
        hasConflictingInventory: false
      }
      const outdatedAppointment = state.appointments.find(a => a.id === appointment.id)

      return {
        ...state,
        appointments: outdatedAppointment
          ? state.appointments.map(a =>
            a.id === appointment.id ? { ...outdatedAppointment, ...newAppointment } : a
          )
          : [...state.appointments, newAppointment],
        updateAppointmentIsLoading: false,
        updateAppointmentErrorMessage: null,
        editingAppointment:
          state.isUpsertAppointmentVisible && state.editingAppointment
            ? { ...state.editingAppointment, ...newAppointment }
            : null,
        editingAppointmentIssues: state.isUpsertAppointmentVisible
          ? appointment.meta.appointmentIssues
          : emptyAppointmentIssues,
        editingAppointmentSuggestionsTimes: state.isUpsertAppointmentVisible
          ? appointment.meta.suggestions
          : []
      }
    },
    updateAppointmentFailure (state, { payload }) {
      state.updateAppointmentIsLoading = false
      state.updateAppointmentErrorMessage = payload
    },
    updateAppointments (state, { payload }) {
      state.appointments = [...state.appointments, ...payload].filter((appointment, index) => {
        return state.appointments.findIndex(a => a.id === appointment.id) === index
      })

      state.updateAppointmentIsLoading = false
      state.updateAppointmentErrorMessage = null
    },
    getAllAppointmentStatusesLoading (state) {
      state.getAllAppointmentStatusesIsLoading = true
      state.getAllAppointmentStatusesErrorMessage = ''
    },
    getAllAppointmentStatusesSuccess (state, { payload }) {
      state.appointmentStatuses = payload
      state.getAllAppointmentStatusesIsLoading = false
      state.getAllAppointmentStatusesErrorMessage = null
    },
    getAllAppointmentStatusesFailure (state, { payload }) {
      state.appointmentStatuses = []
      state.getAllAppointmentStatusesIsLoading = false
      state.getAllAppointmentStatusesErrorMessage = payload
    },
    onCreateAppointment (state) {
      state.isUpsertAppointmentVisible = true
      state.editingAppointmentTab = 0
      state.editingAppointment = null
    },
    moveAppointmentSuccess (state) {
      state.openEditAppointmentIsLoading = false
    },
    changeEditAppointmentTab (state, { payload }) {
      state.editingAppointmentTab = payload
      state.isUpsertAppointmentVisible = true
    },
    closeUpsertAppointment (state) {
      state.isUpsertAppointmentVisible = false
      state.openEditAppointmentIsLoading = false
      state.editingAppointmentTab = 0
      state.editingAppointment = null
    },
    getAppointmentsForWarehouseLoading (state) {
      state.getAppointmentsForWarehouseIsLoading = true
      state.getAppointmentsForWarehouseErrorMessage = ''
    },
    getAppointmentsForWarehouseSuccess (state) {
      state.getAppointmentsForWarehouseIsLoading = false
      state.getAppointmentsForWarehouseErrorMessage = null
    },
    getAppointmentsForWarehouseFailure (state, { payload }) {
      state.getAppointmentsForWarehouseIsLoading = false
      state.getAppointmentsForWarehouseErrorMessage = payload
    },
    clearRequestLoading (state) {
      state.clearRequestIsLoading = true
      state.clearRequestErrorMessage = null
    },
    clearRequestSuccess (state, { payload }) {
      state.clearRequestIsLoading = false
      state.clearRequestErrorMessage = null
      state.editingAppointment = {
        ...payload,
        meta: undefined
      }
    },
    clearRequestFailure (state, { payload }) {
      state.clearRequestIsLoading = false
      state.clearRequestErrorMessage = payload
    },
    mergeAppointmentCounts (state, { payload }) {
      return {
        ...state,
        appointmentCounts: {
          ...state.appointmentCounts,
          ...payload
        }
      }
    },
    setRecalculateDurationFlag (state, { payload }) {
      state.recalculateDurationFlag = payload
    },
    openDeleteAppointment (state, { payload }) {
      state.removingAppointment = payload
    },
    closeDeleteAppointment (state) {
      state.removingAppointment = null
    },
    editingAppointmentIssuesReset (state) {
      state.editingAppointmentIssues = {
        hasPastDate: false,
        hasLowInventory: false,
        hasConflictingInventory: false
      }
    },
    editingAppointmentSuggestionsReset (state) {
      state.editingAppointmentSuggestionsTimes = []
    },
    onSearchChange (state, { payload }) {
      return {
        ...state,
        ...payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(deleteAppointment.fulfilled, (state, { payload }) => {
        state.appointments = state.appointments.filter(a => a.id !== payload)
      })
      .addCase(openEditAppointment.pending, (state, { meta }) => {
        state.openEditAppointmentIsLoading = meta.arg.appointment.id || true
      })
      .addCase(openEditAppointment.fulfilled, (state, { payload }) => {
        const { appointment, tab } = payload
        state.isUpsertAppointmentVisible = true
        state.openEditAppointmentIsLoading = false
        state.editingAppointmentTab = tab
        state.editingAppointment = appointment
      })
      .addCase(createAppointment.fulfilled, (state, { payload }) => {
        const appointment = payload
        const newAppointment = {
          ...appointment,
          meta: undefined
        }
        const newEditingAppointment = {
          ...state.editingAppointment,
          ...newAppointment
        }
        const emptyAppointmentIssues = {
          hasPastDate: false,
          hasLowInventory: false,
          hasConflictingInventory: false
        }

        state.createAppointmentIsLoading = false
        state.createAppointmentErrorMessage = null
        state.editingAppointment = state.isUpsertAppointmentVisible ? newEditingAppointment : null
        state.editingAppointmentIssues = state.isUpsertAppointmentVisible
          ? appointment.meta.appointmentIssues
          : emptyAppointmentIssues
        state.editingAppointmentSuggestionsTimes = state.isUpsertAppointmentVisible
          ? appointment.meta.suggestions
          : []
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        appointmentsSlice.caseReducers.updateAppointmentSuccess(state, action)
      })
    builder.addCase(fetchAppointmentsNextPage.fulfilled, state => {
      state.currentPage = state.currentPage + 1
    })
    builder
      .addCase(fetchAppointments.pending, state => {
        state.getAppointmentIsLoading = true
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        const { data, pages } = action.payload

        const stateAppointments = state.appointments || []
        const currentAppointments = state.currentPage <= 1 ? [] : stateAppointments
        return {
          ...state,
          appointments: [...currentAppointments, ...data],
          pages,
          getAppointmentIsLoading: false,
          getAppointmentsErrorMessage: null
        }
      })
  }
})

export const {
  setSearchAttributes,
  getAppointmentsFailure,
  createAppointmentFailure,
  deleteAppointmentFailure,
  deleteAppointmentLoading,
  deleteAppointmentSuccess,
  createAppointmentLoading,
  closeDeleteAppointment,
  closeUpsertAppointment,
  moveAppointmentSuccess,
  openDeleteAppointment,
  updateAppointmentFailure,
  updateAppointmentLoading,
  changeEditAppointmentTab,
  editingAppointmentIssuesReset,
  editingAppointmentSuggestionsReset,
  getAllAppointmentStatusesSuccess,
  getAllAppointmentStatusesFailure,
  getAllAppointmentStatusesLoading,
  getAppointmentsForWarehouseFailure,
  getAppointmentsForWarehouseLoading,
  getAppointmentsForWarehouseSuccess,
  getAppointmentFailure,
  getAppointmentLoading,
  onCreateAppointment,
  updateAppointments,
  mergeAppointmentCounts,
  clearRequestLoading,
  clearRequestSuccess,
  clearRequestFailure,
  setRecalculateDurationFlag,
  onSearchChange
} = appointmentsSlice.actions

export default appointmentsSlice.reducer
